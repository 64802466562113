import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { 
    addNewAppointmentInfo, 
    updateState, 
    closeAddAppointmentModal 
} from "../../../store/calendar/calendarReducer";
import useWindowWidth from "../../../hooks/useWindowWidth";
import styles from "./AddAppointmentModal.module.css";
import { Modal, ModalBackground, ModalContent, ModalCloseButton } from "../../../components/common/Modal";
import NewAppointmentType from "./NewAppointmentType";
import SelectTime from "./SelectTime";
import SelectItem from "./SelectItem";
import SelectDate from "./SelectDate";
import ClientInfo from "./ClientInfo";
import RedeemCoupon from "./RedeemCoupon";
import InputField from "../common/InputField";
import CheckboxInputField from "../common/CheckboxInputField";
import EmailCheckbox from "../common/EmailCheckbox";
import AppointmentDateList from "./AppointmentDateList";
import SaveButton from "./SaveButton";

const AddAppointmentModal: React.FC = () => {

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);
    const { devicesWidth: { isLargeDevice } } = useWindowWidth();
    const { 
        validationError,
        activityBy,
        newAppointmentType,
        isRepeatingAppointment,
        newAppointmentInfo,
        isBookingAppointmentForSearchedClient
    } = calendarState;
    const { newAppointmentTitle, isCouponRedeemed } = newAppointmentInfo;

    const closeModal = () => {
        dispatch(closeAddAppointmentModal());
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value, checked } = event.target;

        if(name === "newAppointmentTitle") {
            dispatch(addNewAppointmentInfo({
                name: "newAppointmentTitle",
                value
            }));
            return;
        }

        if(name === "isRepeatingAppointment") {
            dispatch(updateState({
                name: "isRepeatingAppointment",
                value: checked
            }));
            return;
        }

        dispatch(updateState({
            name: "activityBy",
            value
        }));

    }

    return (
        <Modal>
            <ModalBackground />
            <ModalContent
                width={isLargeDevice ? "42%" : "95%"}
                height="90%"
            >
                <ModalCloseButton
                    onClick = {closeModal}
                    color = {"orange"}
                    topPosition="10px"
                    rightPosition="20px"
                    fontSize="1.5rem"
                >
                    X
                </ModalCloseButton>
                <NewAppointmentType />
                {isBookingAppointmentForSearchedClient && <SelectDate />}
                <SelectTime />
                {
                    newAppointmentType === "time_block" 
                    ?
                    <InputField 
                        labelText="Appointment Title"
                        type="text" 
                        name="newAppointmentTitle" 
                        placeholder="e.g. day off, break etc"
                        value={newAppointmentTitle} 
                        handleChange={handleInputChange} 
                        validationError={validationError}
                        validationErrorMessage="appointment title can't be blank" 
                    />
                    : null
                }
                {
                    newAppointmentType === "regular"
                    &&
                    <div className = {styles.regular_appointment}>
                        <div className = {styles.appointment_basic_info}>
                            <SelectItem />
                        </div>
                        <ClientInfo />
                        {!isCouponRedeemed ? <RedeemCoupon /> : null}
                    </div>
                }
                <CheckboxInputField 
                    name="isRepeatingAppointment" 
                    value="Make it a repeating appointment"
                    checked={isRepeatingAppointment}
                    handleChange={handleInputChange} 
                />
                {
                    isRepeatingAppointment
                    ?
                    <AppointmentDateList />
                    :
                    null
                }
                <InputField 
                    customClassName="activity_by_input"
                    labelText="Who is scheduling appointment?"
                    type="text"
                    name="activityBy"
                    value={activityBy}
                    handleChange={handleInputChange}
                />
                <EmailCheckbox />
                <SaveButton />
            </ModalContent>
        </Modal>
    );

}

export default AddAppointmentModal;