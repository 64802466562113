import { useSelector } from "react-redux";
import { RootState } from "../../store";
import styles from "./Calendar.module.css";
import Events from "./Events";
import TimeSlotGroup from "./TimeSlotGroup";

const DayView: React.FC = () => {

    const calendarState = useSelector((state: RootState) => state.calendarState);
    const userState = useSelector((state: RootState) => state.userState);
    const { user } = userState;
    const { selectedStylistList, appointmentList, dayViewDate } = calendarState;

    return (  
        <div className={styles.day_view}>
            {selectedStylistList.map(stylist => {

                const stylistAppointments = appointmentList.filter(item => item.stylistItem?._id === stylist._id);

                return (
                    <div 
                        className = {styles.day_times_container} 
                        key = {stylist._id}
                        style = {{
                            width: `${100 / selectedStylistList.length}%`
                        }}
                    >
                        <div className = {styles.stylist_name}>
                            {stylist.fullName}
                        </div>
                        <div className = {styles.timeslot_groups_container}>
                            <Events 
                                events = {stylistAppointments}
                                date = {dayViewDate}
                            />
                            <TimeSlotGroup 
                                user={user}
                                date = {dayViewDate}
                                stylist = {stylist.fullName}
                                stylistId={stylist._id}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );

}

export default DayView;