import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { IUser } from "../../types/models/User";
import { StartEndHourMinutes } from "../../types/pages/Calendar";
import { timeSlotGroups } from "../../data/calendar/timeSlotGroups";
import { userAccessTypeValues } from "../../data/users";
import { updateState, openAddAppointmentModal } from "../../store/calendar/calendarReducer";
import styles from "./Calendar.module.css";

interface TimeSlotProps {
    user: IUser | null
    date: Date
    stylist: string
    stylistId: string
}

const TimeSlotGroup: React.FC<TimeSlotProps> = ({ user, date, stylist, stylistId }) => {

    const dispatch = useDispatch();
    const calendarState = useSelector((state: RootState) => state.calendarState);

    const { 
        stylistList,
        clickSelectedStartTime, 
        selectedTimes 
    } = calendarState;

    const [availableTimes, setAvailableTimes] = useState<string[]>([])

    useEffect(() => {

        const availableTimeList: string[] = []

        const foundStylist = stylistList.find(item => item._id === stylistId);
        const stylistWorkSchedule = foundStylist?.workSchedule ?? [];
        const weekDay = date.toLocaleString("en-us", { weekday: "long" });

        for(let x = 0; x < timeSlotGroups.length; x++) {

            const timeSlot = timeSlotGroups[x];

            for(let y = 0; y < timeSlot.startEndHourMinutes.length; y++) {

                const { startHourMinutes } = timeSlot.startEndHourMinutes[y];

                const indexOfColon = startHourMinutes.indexOf(":");

                const timeSlotStartHours = Number(startHourMinutes.substring(0, indexOfColon));
                const timeSlotStartMinutes = Number(startHourMinutes.substring(indexOfColon + 1, startHourMinutes.length));

                let totalMinutes = timeSlotStartHours * 60 + timeSlotStartMinutes;

                for(let z = 0; z < stylistWorkSchedule.length; z++) {
                    const { day, startTime, endTime } = stylistWorkSchedule[z];

                    if(!(day === weekDay)) continue;

                    const startTimeHours = Number(startTime.substring(0, startTime.indexOf(":")));
                    const startTimeMinutes = Number(startTime.substring(startTime.indexOf(":") + 1, startTime.length));
        
                    const endTimeHours = Number(endTime.substring(0, endTime.indexOf(":")));
                    const endTimeMinutes = Number(endTime.substring(endTime.indexOf(":") + 1, endTime.length));
        
                    const startTimeInMinutes = startTimeHours * 60 + startTimeMinutes;
                    const endTimeInMinutes = endTimeHours * 60 + endTimeMinutes;

                    if(totalMinutes >= startTimeInMinutes && totalMinutes < endTimeInMinutes) {
                        availableTimeList.push(startHourMinutes);
                    }
                     
                }

            }
        }

        setAvailableTimes(availableTimeList);

    }, [date, stylistId, stylistList])

    const checkUserCanBookAppointment = () => {

        if(user?.userType === "Admin" && !user?.accessTypeList.includes(userAccessTypeValues.maintainAllAgendas)) return false;
        if(user?.userType === "Stylist" && !user?.accessTypeList.includes(userAccessTypeValues.maintainOwnAgendas)) return false;
    
        const isUserAndStylistTheSame = stylistId === user?._id;
        if(!isUserAndStylistTheSame && !user?.accessTypeList.includes(userAccessTypeValues.maintainAllAgendas)) return false;

        return true;

    }
    
    const handleMouseEnter = (startEndHourMinute: StartEndHourMinutes) => {

        if(!clickSelectedStartTime) return;

        const foundTime = selectedTimes.find(item => {
            return item.startHourMinutes === startEndHourMinute.startHourMinutes
        });

        if(foundTime) return;

        const value = [
            ...selectedTimes,
            {
                ...startEndHourMinute,
                stylist: selectedTimes[0].stylist,
                date: selectedTimes[0].date
            }
        ];

        dispatch(updateState({
            name: "selectedTimes",
            value
        }));

    }

    const handleMouseDown = (startEndHourMinute: StartEndHourMinutes) => {

        const canBookAppointment = checkUserCanBookAppointment();
        if(!canBookAppointment) return;

        dispatch(updateState({ 
            name: "clickSelectedStartTime",
            value: startEndHourMinute.startHourMinutes
        }));

        dispatch(updateState({
            name: "selectedTimes",
            value: [{ ...startEndHourMinute, stylist, date }]
        }));

    }

    const handleMouseUp = () => {

        const canBookAppointment = checkUserCanBookAppointment();
        if(!canBookAppointment) return;

        dispatch(updateState({ 
            name: "clickSelectedStartTime",
            value: ""
        }));

        if(!selectedTimes.length) return;

        const appointment_date = selectedTimes[0].date;
        const stylist = stylistList.find(stylistItem => stylistItem.fullName === selectedTimes[0].stylist);
        const startTime = selectedTimes[0].startHourMinutes;
        const endTime = selectedTimes[selectedTimes.length - 1].endHourMinutes;

        if(!stylist) return;

        dispatch(openAddAppointmentModal({
            appointment_date,
            stylist,
            startTime,
            endTime
        }));

    }

    return (
        <>
            {timeSlotGroups.map(timeSlotG => {
                return (
                    <div key={timeSlotG.hour} className={styles.timeslot_group}>
                        {timeSlotG.startEndHourMinutes.map((hourMinutes, index) => {
                            const isAvailable = availableTimes.includes(hourMinutes.startHourMinutes);
                            return (
                                <div 
                                    key={index}
                                    className={`${styles.timeslot} ${isAvailable ? styles.timeslot_available : ""}`}
                                    onMouseDown={() => handleMouseDown(hourMinutes)}
                                    onMouseUp={handleMouseUp}
                                    onMouseEnter={() => handleMouseEnter(hourMinutes)}
                                >
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </>                
    );

}

export default TimeSlotGroup;