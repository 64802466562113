import { useDispatch } from "react-redux";
import { IAppointment } from "../../types/models/Appointment";
import { updateState } from "../../store/calendar/calendarReducer";
import { formatDateTime } from "../../helpers/date-time/dateTimeHelpers";
import { calculateAppointmentPosition } from "../../helpers/calendar/calculateAppointmentPosition";
import styles from "./Calendar.module.css";

interface IProps {
    events: IAppointment[]
    date: Date
}

const Events: React.FC<IProps> = ({ events, date }) => {

    const dispatch = useDispatch();

    const handleClick = (event: IAppointment) => {
        dispatch(updateState({
            name: "appointmentInAction",
            value: event
        }));
    }

    const { dateString } = formatDateTime(date);

    return (
        <div className = {styles.events_container}>
            {events.map(event => {

                const { width, leftPosition } = calculateAppointmentPosition(event, events);

                const indexOfT = event.startTime.indexOf("T");
                const eventDateString = event.startTime.substring(0, indexOfT);

                const eventStartTime = event.startTime.substring(indexOfT + 1, event.startTime.length);

                const hours = Number(eventStartTime.substring(0, eventStartTime.indexOf(":")));
                const minutes = Number(eventStartTime.substring(eventStartTime.indexOf(":") + 1, eventStartTime.length));

                const topPosition = (hours - 4 + (minutes / 60)) * 100;

                // In the stylesheet height of each timeslot is 25px. And each time is containing 15 minutes of
                // appointment duration. So event height per duration minute will be 25 / 15.
                const eventHeight = (25 / 15) * event.duration; 

                if(dateString === eventDateString) {

                    return (
                        <div 
                            key = {event._id}
                            className = {styles.event}
                            onClick = {() => handleClick(event)}
                            style = {{
                                width: `${width}%`,
                                backgroundColor: event.treatmentItem?.category.color ?? "white",
                                left: `${leftPosition}%`,
                                top: `${topPosition}px`,
                                height: `${eventHeight}px`
                            }}
                        >
                            <p>{event.clientName}</p>
                            <p>{event.treatmentTitle} with {event.stylist}</p>
                        </div>
                    );

                } else {
                    return null;
                } 
            })}
        </div>
    );

}

export default Events;